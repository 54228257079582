$background-color: #f6f6fa;
$main-menu-color: green;
$default-background-image: url("../images/asensiot_bg.jpg");

$acceptable-color: #74ea74;
$warning-color: #ffc800;
$danger-color: #ff96a3;
$unsupported-color: lightgrey;
$unknown-color: #c3c3c3;

$progress-color: #09c709;
$link-color: #4183c4;
$light-link-color: #87ceff;

$counter-border-color: black;

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  padding: 0;
  background-color: $background-color;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  flex-direction: column;
}

.AdaComponent {
  &__errorMessage {
    text-align: left;
  }

  &__serviceErrorMessage {
    text-align: left;
  }
}

.Login {
  background-image: $default-background-image;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  height: 100vh;
  overflow: auto;

  &__logoContainer {
    display: flex;
    justify-content: center;

  }

  &__logo {
    width: 64%;
    margin: 60px 18%;
  }

  &__dialog {
    max-width: 500px;
    text-align: left !important;
  }

  &__title {
    margin: 10px 0 30px 0;
    font-size: 1.5em;
  }

  &__login {
    width: fit-content !important;
    height: 35px !important;
    padding: 0 20px !important;
  }
}

.MainMenu {
  margin-bottom: 0.5rem;

  &__item {
    margin: 0 10px;
    color: gray !important;

    &.active {
      color: $main-menu-color !important;
      font-weight: bold !important;
    }

  }

  &__logoContainer {
    display: flex;
    justify-content: center;
  }

  &__logo {
    width: 198px;
    padding: 10px 20px 10px 40px;
  }

  &__nav {
    margin-top: 0 !important;
    width: 100%;
    margin-right: 0.5em;
    font-size: 1.25em !important;
    flex-wrap: wrap;
  }

  &__dropdown {
    margin: 10px 10px 0 0;
    font-size: 1.25em !important;
  }
}

.Analyses {

  margin: 2% !important;
  border-radius: 0 !important;

  &__more {
    text-align: center;
  }

  &__segment {
    border: none !important;
    height: fit-content;
  }

  &__title {
    font-weight: bold !important;
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  &__tools {

  }

  &__filter {
    padding: 0 4px 0 0 !important;
  }

  &__search {
    display: inline-block;
    width: 100%;
  }

  &__analyses_tools {
    display: flex;
    justify-content: space-between;
    margin: 10px;
  }

  &__grouped {

  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__filter {
  }

  &__filter--ok {
    background-color: $acceptable-color !important;
  }

  &__filter--warning {
    background-color: $warning-color !important;
  }

  &__filter--danger {
    background-color: $danger-color !important;
  }

  &__filter--unsupported {
    background-color: $unsupported-color !important;
  }
}

.DiagnosisFilter {
  width: 100% !important;
  background-color: $background-color !important;
  border: none !important;
}

.StatusFilter {

}

.AnalysesItem {
  &__list {
    padding: 10px 0 10px 0 !important
  }

  &__diagnosis {
    font-weight: bold;
    margin: 10px 0 5px -5px;
  }

  &__right-float {
    color: gray;
    float: right;
  }

  &__item-index {
    color: #494949;
  }

  &__ok {
    /* background-color: $acceptable-color; */
  }

  &__warning {
    /* background-color: $warning-color; */
  }

  &__danger {
    /* background-color: $danger-color; */
  }

  &__unsupported {
    /* background-color: $unsupported-color; */
  }
}

.MachinePath {
  .section {
    color: #717171
  }
}

.AnalysesGroup {
  &__group {
    padding-right: 0 !important;
    padding-bottom: 0 !important;
  }

  &__list {
    padding: 0 !important;
    padding-left: 0.5rem !important;
  }

  &__diagnosis {
    padding-left: 0.5rem !important;
  }

  &__item--ok {
    background-color: $acceptable-color;
  }

  &__item--warning {
    background-color: $warning-color;
  }

  &__item--danger {
    background-color: $danger-color;
  }

  &__item--unsupported {
    background-color: $unsupported-color;
  }

  &__link--ok {
    background-color: $acceptable-color;
  }

  &__link--warning {
    background-color: $warning-color;
  }

  &__link--danger {
    background-color: $danger-color;
    color: $light-link-color;
  }

  &__link--unsupported {
    background-color: $unsupported-color;
  }
}

.DiagnosisSummary {

  display: block;

  &__ok {
    /* background-color: $acceptable-color; */
    width: fit-content;
  }

  &__warning {
    /* background-color: $warning-color; */
    width: fit-content;
  }

  &__danger {
    /* background-color: $danger-color; */
    width: fit-content;
  }

  &__unsupported {
    /* background-color: $unsupported-color; */
    width: fit-content;
  }

  &__validated {
    margin-left: 1rem;
  }
}

.Analysis {

  &__table {
    border-radius: 0 !important;
    box-shadow: none;
  }

  &__summary {
  }

  &__machineLog {
    margin-top: 1rem !important;
  }

  &__accordion_title {
    border: none !important;
    font-size: 1.2em !important;
    color: #5f5f5f !important;
    display: flex;

    svg {
      margin-right: 15px;
    }
  }

  &__title {
    font-weight: bold !important;
    padding: 20px 0 0 20px !important;
    margin: 20px 0 0 0 !important;
  }

  &__machinegroup-images {
    display: flex;
    text-align: center;
    overflow: auto;
    max-height: 500px;
  }

  &__machinegroup-image {
    cursor: pointer;

    img {
      margin: auto;
    }
  }

}

.AnalysisDetails {
  border-radius: 0 !important;
  box-shadow: none !important;

  &__tools {
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__path {
    margin-top: 1em !important;
  }

  &__table {
    border-radius: 0 !important;
    box-shadow: none !important;
  }

  &__links {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none !important;
  }

  &__trend {
    display: inline-block;
    width: 100%;
    max-width: 640px;
    vertical-align: middle;
  }
}

.ReferenceComparisons {
  border-radius: 0 !important;
  box-shadow: none !important;

  &__label {
    border-radius: 0 !important;
    box-shadow: none;
  }

  &__table {
    border-radius: 0 !important;
    box-shadow: none;
  }
}

.KeyFigures {
  border-radius: 0 !important;
  box-shadow: none !important;
}

// .Incident {
//   border-radius: 0 !important;

//   &__tools {
//     display: flex;
//     flex-direction: row-reverse;
//     align-items: center;
//   }

//   &__tool {
//     box-shadow: none !important;
//     background: none !important;
//     :focus {
//       background: none !important;
//     }
//     &:hover {
//       background: none !important;
//     }
//   }

//   &__summary {
//     margin-top: 1em !important;
//   }

//   &__path {
//     margin-top: 1em !important;
//   }

//   &__table {
//     border-radius: 0 !important;
//     border: 0 !important;
//     box-shadow: none;
//   }
// }

// .Resolution {
//   border-radius: 0 !important;

//   &__button {
//     display: block;
//     float: right;
//   }
// }

// .ResolutionSummary {
//   border-radius: 0 !important;

//   &__table {
//     border-radius: 0 !important;
//   }
// }

.MachineLog {

  border-radius: 0 !important;

  &__more {
    text-align: center;
  }

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__sensor-filters {
    text-align: end;
  }

  &__sensor-filter {
    margin-right: 20px;

  }

  &__search .input {
    width: 200px !important;
    height: 2.25em;
  }

  &__entries {
    padding: 0 !important;
    margin-top: 0 !important;
  }
}

.LogEntries {

  border-radius: 0 !important;

  &__more {
    text-align: center;
  }

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }
}

.LogEntry {

  border-radius: 0 !important;

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__button {
    display: block;
    float: right;
  }
}

.HierarchyImporter {

  margin: 2% !important;
  padding: 1em !important;

  &__more_sensor_groups {
    text-align: center;
  }

  &__attachment {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80%;
    white-space: nowrap;
    display: inline-block;
  }

  &__tasks {
    list-style-type: none;
    padding: 0;
    margin-left: 0.5em;
  }

  &__remove {
    box-shadow: none !important;
    padding: 0 0.3em !important;
  }

  &__buttons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__submit {
    margin-right: 0.5em !important;
  }
}

.DataImporter {

  margin: 2% !important;
  padding: 1em !important;

  &__more_sensor_groups {
    text-align: center;
  }

  &__more_labels {
    text-align: center;
  }

  &__attachment {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80%;
    white-space: nowrap;
    display: inline-block;
  }

  &__tasks {
    list-style-type: none;
    padding: 0;
    margin-left: 0.5em;
  }

  &__remove {
    box-shadow: none !important;
    padding: 0 0.3em !important;
  }

  &__buttons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__submit {
    margin-right: 0.5em !important;
  }
}

.CustomReferences {
  border-radius: 0 !important;
}

.CustomReference {
  border-radius: 0 !important;

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__remove {
    box-shadow: none !important;
    padding: 0 0.3em !important;
  }

  &__button {
    display: block;
    float: right;
  }
}

.Task {
  margin: 2% !important;
  border-radius: 0 !important;

  &__toolbar {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__toolbar_button {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__table {
    border-radius: 0 !important;
  }
}

.Tasks {

  border-radius: 0 !important;

  &__more {
    text-align: center;
  }

  &__progress {
    display: table-cell !important;
    min-width: 29px !important;
    text-align: center;
    vertical-align: middle;
    color: $progress-color;
  }

  &__status {
    padding: 0 !important;
    vertical-align: middle !important;
  }

  &__task {
    min-height: 38px !important;
  }

  &__action {
    display: table-cell;
  }

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }
}

.Labels {
  border-radius: 0 !important;

  &__more {
    text-align: center;
  }

  &__action {
    display: table-cell !important;
    vertical-align: top !important;
  }

  &__select {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }

    &:active {
      background: none !important;
    }
  }

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__input {
    margin-top: 1rem !important;
  }
}

.SensorFolderPath {
  padding: 4px 0 !important;

  &__sublist {
    padding: 0 !important;
    border-top: 1px solid #d7d7d7;
  }

  &__content {
    padding: 0 0 0 15px !important;

    .header {
      margin: 10px 0 !important;
      display: flex;
      align-items: center;
    }

    svg {
      margin: 0 5px;
    }

    a {
      color: #585858;
    }
  }
}

.Hierarchy {

  margin: 2% !important;
  border-radius: 0 !important;

  &__filter-title {
    font-weight: bold !important;
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }


  &__toggle {
    vertical-align: top !important;
    float: right !important;
  }

  &__item {
    display: inline-block;

    svg {
      margin: 0 5px 0 10px;
      height: 1.25em;
    }

  }

  &__topaccordion {
    margin-top: 0 !important;
  }

  &__accordion {
  }

  &__filters {
    box-shadow: none !important;
    border: none !important;
  }

  &__tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }


  &__searchForm {
    width: 100%;
    display: inline-block;
    margin-bottom: 1rem;
  }

  &__search {
    width: 100%;
    display: inline-block;
  }

  &__tool {
    display: inline-block;
    margin-bottom: 10px !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__root_menu {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__item_menu {
    margin-left: 0.5rem;
  }

  &__counter {
    padding-left: 1rem !important;
  }

  &__title {
    padding: 0.2rem 0 !important;
  }

  &__title--ok {
    background-color: $acceptable-color;
  }

  &__title--warning {
    background-color: $warning-color;
  }

  &__title--danger {
    background-color: $danger-color;
  }

  &__title--unsupported {
    background-color: $unsupported-color;
  }

  &__title--unknown {
    background-color: $unknown-color;
  }

  &__status--ok {
    background-color: $acceptable-color;
  }

  &__status--warning {
    background-color: $warning-color;
  }

  &__status--danger {
    background-color: $danger-color;
  }

  &__status--unsupported {
    background-color: $unsupported-color;
  }

  &__status--unknown {
    background-color: $unknown-color;
  }

  &__accordion {
  }

  &__container {
  }

  &__accordion--ok {
    background-color: $acceptable-color;
  }

  &__accordion--warning {
    background-color: $warning-color;
  }

  &__accordion--danger {
    background-color: $danger-color;
  }

  &__accordion--unsupported {
    background-color: $unsupported-color;
  }

  &__accordion--unknown {
    background-color: $unknown-color;
  }

  &__subaccordion {
    margin-top: 0 !important;
    padding-left: 10% !important;
  }

  &__accordion-divider {
    position: absolute !important;
    left: 0;
    right: 0;
    margin: -3px 15px 0 14px !important;
  }

  &__subaccordion--ok {
    background-color: $acceptable-color;
  }

  &__subaccordion--warning {
    background-color: $warning-color;
  }

  &__subaccordion--danger {
    background-color: $danger-color;
  }

  &__subaccordion--unsupported {
    background-color: $unsupported-color;
  }

  &__subaccordion--unknown {
    background-color: $unknown-color;
  }

  &__link--ok {
    color: $link-color !important;
  }

  &__link--warning {
    color: $link-color !important;
  }

  &__link--danger {
    color: $light-link-color !important;
  }

  &__link--unsupported {
    color: $link-color !important;
  }

  &__link--unknown {
    color: $link-color !important;
  }

  $counter-border: 1px solid $counter-border-color;
  $counter-border-radius: 1rem;

  .counter {
    border: $counter-border;
    border-radius: $counter-border-radius;
    padding: 0 5px 0 5px;
  }

  &__counter--ok {
    @extend .counter;
    background-color: $acceptable-color;
  }

  &__counter--warning {
    @extend .counter;
    background-color: $warning-color;
  }

  &__counter--danger {
    @extend .counter;
    background-color: $danger-color;
  }

  &__counter--unsupported {
    @extend .counter;
    background-color: $unsupported-color;
  }

  &__sensor {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
    padding-right: 1rem !important;
  }

  &__sensor_item {
    display: inline-block;
    margin-left: 1.5rem !important;

    svg {
      margin: 0 5px 0 10px;
      height: 1.25em;
    }
  }
}

.UserHome {

  margin: 2% !important;
  border-radius: 0 !important;

  &__title {
    font-weight: bold !important;
    margin-top: 0 !important;
    margin-bottom: 2rem !important;
  }

  &__more_sensor_groups {
    text-align: center;
  }

  &__task_progress {
    display: table-cell !important;
    text-align: center;
    vertical-align: middle;
    color: green;
  }

  &__task_status {
    padding: 0 !important;
    vertical-align: middle !important;
  }

  &__task {
    min-height: 38px !important;
  }

  &__select_button {
    box-shadow: none !important;
  }

  &__more_tasks {
    text-align: center;
  }

  &__segment {
    border: none !important;
    box-shadow: none !important;
    height: fit-content;
  }

  &__attachment {
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 80%;
    white-space: nowrap;
    display: inline-block;
  }

  &__analyze {
    margin-top: 25px;
  }

  &__reports {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &__tool {
  }

  &__refresh_button {
    box-shadow: none !important;
  }

  &__task_list {
    max-height: 70vh !important;
    overflow: auto;
    margin: 5px 0px;

    & thead {
      background-color: $background-color;
    }

    & tr td {
      border-top: 0 !important;
    }
  }

  &__status_cell {
    text-align: center !important;
    width: 100px;
  }

  &__select_cell {
    text-align: end !important;
    padding-right: 20px !important;
  }

  &__search .input {
    width: 200px !important;
    height: 2.25em;
  }

  &__selectFilesBtn {
    color: black !important;
    font-weight: bold !important;
    border: solid 1px black !important;
    padding: 10px 30px !important;
    background-color: transparent !important;
  }
}

.TechnicianHome {
  margin: 2% !important;
  border-radius: 0 !important;

  &__table {
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none;
  }
}

.UserProfile {
  margin: 2% 4% !important;
  border-radius: 0 !important;
  padding: 4% 4% 50px 4% !important;

  &__table {
    border-radius: 0 !important;
    border: 0 !important;
    box-shadow: none;
  }

  &__button {
    display: block;
    float: right;
  }

  &__changePassword {
    max-width: 300px !important;
  }
}

.Locations {
  border-radius: 0 !important;
}

.Location {
  margin: 2% !important;
  border-radius: 0 !important;

  &__tools {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__button {
    display: block;
    float: right;
  }
}

.MachineGroups {
  border-radius: 0 !important;
}

.MachineGroup {
  margin: 2% !important;
  border-radius: 0 !important;

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__button {
    display: block;
    float: right;
  }
}

.Machines {
  border-radius: 0 !important;
}

.Machine {
  margin: 2% !important;
  border-radius: 0 !important;

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__reference {
    margin-left: 1em !important;
  }

  &__reference_label {
    display: inline !important;
  }

  &__reference_link {
    float: right !important;
    margin-right: 0.5em;
  }

  &__button {
    display: block;
    float: right;
  }

  &__log_link {
    margin-top: 1rem !important;
  }
}

.Sensors {
  border-radius: 0 !important;
}

.Sensor {
  margin: 2% !important;
  border-radius: 0 !important;

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__reference {
    margin-left: 1em !important;
  }

  &__reference_label {
    display: inline !important;
  }

  &__reference_link {
    float: right !important;
    margin-right: 0.5em;
  }

  &__threshold {
    width: 8em !important;
  }

  &__button {
    display: block;
    float: right;
  }
}

.Inventory {
  margin: 2% !important;
  padding: 1em !important;
  border-radius: 0 !important;

  &__header {
    opacity: 1 !important;
  }

  &__item {
    margin-left: 0.75rem !important;
  }
}

.InventoryList {
  &__incomplete {
    margin-left: 0.5em !important;
  }

  &__buttons {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }
}

.Dimmer {
  &__loader {
    top: 200px !important;
  }
}

.SensorGroups {
  border-radius: 0 !important;
}

.SensorGroup {
  border-radius: 0 !important;

  &__sublist {
    padding: 2px 0 0 0 !important;
    width: 100% !important;
  }

  &__check {
    padding-top: 2px !important;
  }

  &__select {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__item {
    padding: 0 !important;
  }

  &__icon {
    padding: 0 !important;
    padding-top: 4px !important;
  }

  &__content {
    padding: 0 0 0 2px !important;
  }

  &__selected {
    background-color: lightgreen;
    margin-right: 40px !important;
  }

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__button {
    display: block;
    float: right;
  }
}

.Loader {
  border-radius: 0 !important;
}

.ErrorFallback {
  border-radius: 0 !important;
}

.Diagnosis {
  border-radius: 0 !important;

  &__tools {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  }

  &__tool {
    box-shadow: none !important;
    background: none !important;

    :focus {
      background: none !important;
    }

    &:hover {
      background: none !important;
    }
  }

  &__button {
    display: block;
    float: right;
  }

  &__table {
    border-radius: 0 !important;
    box-shadow: none;
  }

  &__todo-dropdown * {
    color: #4b4b4b !important;
    opacity: 1 !important;
  }

}

.GroupAnalyses {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &__container {
    flex: 1;
  }

  &__exclude {
    text-align: center;
    margin-bottom: -10px;
  }

  &__analysis {
    padding: 0 !important;
  }
}

/** Fancier scrollbars •ᴗ• */
::-webkit-scrollbar {
  width: 10px; /* for vertical scrollbars */
  height: 10px; /* for horizontal scrollbars */
}

::-webkit-scrollbar-track {
  background: rgba(175, 175, 175, 0.25);
}

::-webkit-scrollbar-thumb {
  background: rgba(19, 19, 19, 0.13);
  border-radius: 10px;
}
